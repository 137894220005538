<bx-page-message>
  <h2 data-spec="table-view-unavailable-message">Table view not available</h2>
  @if (currentState === IndexStateEnum.REINDEXING_ERROR) {
    <div>
      <p class="mb-4">
        This table is unable to be viewed, as indexing did not complete successfully.
      </p>
      <p>Please retry,</p>
      <p>
        or contact support
        <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">here</a> if this is not
        expected.
      </p>
    </div>
  } @else if (currentState === IndexStateEnum.ABSENT) {
    <div>
      <p class="mb-4">This table has been archived as it was not accessed for a 12 months.</p>
      <p class="mb-4">To access your results faster restore only the required table(s).</p>
    </div>
  }
  <div style="align-items: center">
    <div><b>Suggested actions:</b></div>
    <br />
    <div>
      <div>
        <button class="btn btn-outline-primary" (click)="reIndexCurrentTable()">
          Restore {{ selectedTable.displayName }} table
        </button>
      </div>
      <br />
      @if ((deIndexedTables$ | async).length > 1) {
        <div>
          <button class="btn btn-outline-primary" (click)="reIndexAllTables()">
            Restore all tables({{ allTableNames$ | async }})
          </button>
        </div>
      }
    </div>
  </div>
</bx-page-message>
