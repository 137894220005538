import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import {
  DocumentTableUIIndexState,
  IndexStateEnum,
} from '../../document-table-service/document-table-state/document-table-state';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { NgsReIndexTablesComponent } from '../ngs-re-index-tables/ngs-re-index-tables.component';
import { NgsCancleReindexingComponent } from '../ngs-cancle-reindexing/ngs-cancle-reindexing.component';

@Component({
  selector: 'bx-ngs-table-restoring-overlay',
  templateUrl: './ngs-table-restoring-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    NgbProgressbar,
    NgsReIndexTablesComponent,
    NgsCancleReindexingComponent,
  ],
})
export class NgsTableRestoringOverlayComponent {
  @HostBinding('class') readonly hostClass = 'd-block w-100 h-100';
  @Input() uiIndexState: DocumentTableUIIndexState;
  @Input() selectedTable: DocumentTable;
  protected readonly IndexStateEnum = IndexStateEnum;
}
