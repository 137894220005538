import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  HostBinding,
} from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, Subscription, switchMap } from 'rxjs';
import { catchError, shareReplay, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { CleanUp } from '../../../shared/cleanup';
import {
  DocumentTableStore,
  DocumentTableUIIndexState,
  IndexStateEnum,
} from '../../document-table-service/document-table-state/document-table-state';
import {
  listenToReIndexingDocumentTableEvents,
  listenToReIndexingJobProgress,
} from '../../document-table-service/document-table-state/document-table-state.actions';
import { Store } from '@ngrx/store';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { NgsCancleReindexingComponent } from '../ngs-cancle-reindexing/ngs-cancle-reindexing.component';

@Component({
  selector: 'bx-ngs-document-restore-screen',
  templateUrl: './ngs-document-restore-screen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, NgbProgressbar, AsyncPipe, NgsCancleReindexingComponent],
})
export class NgsDocumentRestoreScreenComponent
  extends CleanUp
  implements OnInit, OnDestroy, OnChanges
{
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @Input() documentID: string;
  @Input() tableName: string;
  documentID$: ReplaySubject<string>;
  tableName$: ReplaySubject<string>;
  uiIndexState$: Observable<DocumentTableUIIndexState>;
  private tableIdentifier$: Observable<[string, string]>;
  protected readonly IndexStateEnum = IndexStateEnum;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly documentTableStateService: DocumentTableStateService,
    private readonly store: Store<DocumentTableStore>,
  ) {
    super();
    this.documentID$ = new ReplaySubject(1);
    this.tableName$ = new ReplaySubject(1);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.documentID$.complete();
    this.tableName$.complete();
    this.subscriptions.unsubscribe();
  }

  ngOnChanges({ documentID, tableName }: SimpleChanges) {
    if (documentID?.currentValue) {
      this.documentID$.next(documentID.currentValue);
    }
    if (tableName?.currentValue) {
      this.tableName$.next(tableName.currentValue);
    }
  }

  ngOnInit(): void {
    this.tableIdentifier$ = combineLatest([this.documentID$, this.tableName$]);
    this.uiIndexState$ = this.tableIdentifier$.pipe(
      switchMap(([documentID, tableName]) =>
        this.documentTableStateService.getUIIndexState(documentID, tableName),
      ),
      shareReplay({ bufferSize: 0, refCount: true }),
    );
    this.uiIndexState$
      .pipe(take(1), withLatestFrom(this.documentID$), takeUntil(this.ngUnsubscribe))
      .subscribe(([state, documentID]) => {
        this.store.dispatch(
          listenToReIndexingDocumentTableEvents({
            documentID: documentID,
            tableName: state.tableName,
          }),
        );
        if (state.indexingJobID)
          this.store.dispatch(
            listenToReIndexingJobProgress({
              documentID: documentID,
              tableNames: [state.tableName],
              indexingJobID: state.indexingJobID,
            }),
          );
      });
  }

  restoreDefaultTable() {
    this.tableIdentifier$
      .pipe(
        switchMap(([documentID, tableName]) =>
          this.documentTableStateService
            .forceRestoreTable(documentID, tableName)
            // If the table doesn't exist, it will throw an error.
            .pipe(catchError(() => of(undefined))),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  reIndexDefaultTable() {
    this.documentTableStateService.reIndexTables(this.documentID, [this.tableName]);
  }
}
