import { Injectable } from '@angular/core';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import {
  DocumentActivityEventKind,
  JobActivityEventKind,
} from '../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  DocumentActivityEvent,
  DocumentStatusKind,
} from '../../../../nucleus/v2/models/activity-events/document-activity-event.model';
import { DocumentServiceHttpV1 } from '../../../../nucleus/services/documentService/document-service.v1.http';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { JobsService } from '../../jobs/job.service';
import {
  getJobID,
  JobProgressedActivityEvent,
} from '../../../../nucleus/v2/models/activity-events/job-activity-event.model';

@Injectable({
  providedIn: 'root',
})
export class ReIndexingEventsService {
  constructor(
    private readonly activityStreamService: ActivityStreamService,
    private readonly documentService: DocumentServiceHttpV1,
    private readonly dialogService: DialogService,
    private readonly jobService: JobsService,
  ) {}

  startReIndexing(documentID: string, ...tables: string[]) {
    return this.documentService.reIndexTables(documentID, tables);
  }

  listenReIndexingDocumentActivity(
    documentID: string,
    ...tables: string[]
  ): Observable<DocumentActivityEvent> {
    return this.activityStreamService.listenToDocumentActivity(documentID).pipe(
      tap((activity) => {
        if (
          activity.event.kind === DocumentActivityEventKind.TABLE_STATUS_UPDATED &&
          activity.event.status.kind === DocumentStatusKind.ERROR &&
          tables.includes(activity.event.tableName)
        ) {
          throw new Error();
        }
      }),
    );
  }

  listenReIndexingJobProgress(jobID: string): Observable<number> {
    return this.activityStreamService.listenToJobActivity().pipe(
      filter(
        (jobActivity) =>
          getJobID(jobActivity.event) === jobID &&
          jobActivity.event.kind === JobActivityEventKind.JOB_PROGRESSED,
      ),
      map((jobActivityEvent) => (jobActivityEvent.event as JobProgressedActivityEvent).progress),
    );
  }

  cancelReIndexing(jobID: string) {
    this.dialogService.showConfirmationDialog({
      title: `Are you sure you want to cancel current restore job?`,
      confirmationButtonColor: 'danger',
      cancelButtonText: 'No',
      confirmationObservable: this.jobService.cancel(jobID).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            return of({
              errorMessage:
                'Error occurred while cancelling job. It may have already completed, failed or been cancelled.',
            });
          } else {
            return of({ errorMessage: 'An unexpected error occurred when cancelling this job.' });
          }
        }),
      ),
    });
  }
}
