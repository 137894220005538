import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { IndexStateEnum } from '../../document-table-service/document-table-state/document-table-state';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-re-index-tables',
  templateUrl: './ngs-re-index-tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, AsyncPipe],
})
export class NgsReIndexTablesComponent implements OnInit, OnDestroy {
  @Input() selectedTable: DocumentTable;
  @Input() currentState: IndexStateEnum;
  deIndexedTables$: Observable<DocumentTable[]>;
  allTableNames$: Observable<string>;
  protected readonly IndexStateEnum = IndexStateEnum;
  private readonly subscriptions = new Subscription();

  constructor(private readonly documentTableStateService: DocumentTableStateService) {}

  ngOnInit(): void {
    this.deIndexedTables$ = this.documentTableStateService.getReIndexingRequiredTables(
      this.selectedTable.documentID,
    );
    this.allTableNames$ = this.deIndexedTables$.pipe(
      map((tables) => tables.map((table) => table.displayName).join(' ,')),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  reIndexCurrentTable() {
    this.subscriptions.add(this.reIndexTables([this.selectedTable.name]).subscribe(() => {}));
  }

  reIndexAllTables() {
    this.subscriptions.add(
      this.deIndexedTables$
        .pipe(
          take(1),
          switchMap((tables) => this.reIndexTables(tables.map((table) => table.name))),
        )
        .subscribe(() => {}),
    );
  }

  private reIndexTables(tableNames: string[]) {
    return this.documentTableStateService.reIndexTables(this.selectedTable.documentID, tableNames);
  }
}
