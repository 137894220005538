<bx-page-message xmlns="http://www.w3.org/1999/html">
  @if (uiIndexState$ | async; as uiIndexState) {
    @if (uiIndexState.currentIndexState === IndexStateEnum.ARCHIVED) {
      <div>
        <h2 data-spec="preview-unavailable-message">Preview not available</h2>
        <p class="mb-4">Your document was archived because it was not accessed for 30 days.</p>
        <button class="btn btn-outline-primary" (click)="restoreDefaultTable()">
          Restore Preview
        </button>
      </div>
    } @else if (
      uiIndexState.currentIndexState === IndexStateEnum.ABSENT ||
      uiIndexState.currentIndexState === IndexStateEnum.REINDEXING_ERROR
    ) {
      <div>
        <h2 data-spec="preview-unavailable-message">Preview not available</h2>
        <p class="mb-4">
          This preview has been archived as the document was not accessed for 12 months.
        </p>
        <p>You can restore the preview here or open full document to restore them.</p>
        <button class="btn btn-outline-primary" (click)="reIndexDefaultTable()">
          Restore Preview
        </button>
      </div>
    } @else if (uiIndexState.currentIndexState === IndexStateEnum.RESTORING_ERROR) {
      <div>
        @if (uiIndexState.error === 'Failed to restore document table') {
          <h2>An error occurred while restoring the table.</h2>
          <p>Try reloading the document.</p>
        }
      </div>
    } @else if (uiIndexState.currentIndexState === IndexStateEnum.RESTORING) {
      <div data-spec="restoring-document-message">
        <h2>Preview is being restored</h2>
        <p>This preview has been archived the document was not accessed for 30 days.</p>
      </div>
    } @else if (uiIndexState.currentIndexState === IndexStateEnum.REINDEXING) {
      <div>
        <h2>Preview is being restored</h2>
        <p>
          You can navigate away from this page while the job completes or it will be automatically
          loaded when it restored.
        </p>
      </div>
    }
    @if (uiIndexState.progress > -1) {
      <ngb-progressbar
        class="w-100"
        type="info"
        [value]="uiIndexState.progress"
        [striped]="true"
        [animated]="true"
        [showValue]="true"
      >
      </ngb-progressbar>
    }
    <br />
    <bx-ngs-cancle-reindexing [uiIndexState]="uiIndexState"></bx-ngs-cancle-reindexing>
  }
</bx-page-message>
